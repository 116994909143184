var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "place-order"
  }, [_vm.showPage ? _c('div', {
    staticClass: "place-order-container"
  }, [_c('van-cell', {
    staticClass: "top",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('span', {
          staticClass: "title"
        }, [_vm._v(_vm._s(_vm.goodsData.title))])];
      },
      proxy: true
    }, {
      key: "label",
      fn: function () {
        return [_c('div', [_vm.$route.query.skill ? _c('span', {
          staticClass: "price"
        }, [_c('span', {
          staticStyle: {
            "font-size": "12px"
          }
        }, [_vm._v("¥")]), _c('span', {
          staticStyle: {
            "font-size": "24px"
          }
        }, [_vm._v(_vm._s(_vm.goodsData.price_pin.split(".")[0]))]), _vm._v("."), _c('span', [_vm._v(_vm._s(_vm.goodsData.price_pin.split(".")[1]))])]) : _c('span', {
          staticClass: "price"
        }, [_c('span', {
          staticStyle: {
            "font-size": "12px"
          }
        }, [_vm._v("¥")]), _c('span', {
          staticStyle: {
            "font-size": "24px"
          }
        }, [_vm._v(_vm._s(_vm.goodsData.price.split(".")[0]))]), _vm._v("."), _c('span', [_vm._v(_vm._s(_vm.goodsData.price.split(".")[1]))])]), _c('span', {
          staticClass: "originPrice"
        }, [_vm._v("¥" + _vm._s(_vm.goodsData.price_show))])]), _vm.goodsData.start_time || _vm.goodsData.end_time ? _c('div', [_vm._v(" 使用时间：" + _vm._s(_vm.goodsData.start_time) + " "), _vm.goodsData.end_time ? _c('span', [_vm._v(" 至 ")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.goodsData.end_time) + " ")]) : _vm._e()];
      },
      proxy: true
    }], null, false, 1844399407)
  }), _c('div', {
    staticClass: "buy-number"
  }, [_c('div', {
    staticClass: "lable"
  }, [_vm._v("购买数量")]), _c('van-stepper', {
    attrs: {
      "button-size": "22",
      "min": 1,
      "max": _vm.buyLimit,
      "disable-input": "",
      "theme": "round"
    },
    on: {
      "change": _vm.changeBuyCount
    },
    model: {
      value: _vm.buyCount,
      callback: function ($$v) {
        _vm.buyCount = $$v;
      },
      expression: "buyCount"
    }
  })], 1), _vm.$route.query.is_express == 1 ? _c('div', {
    staticClass: "van-address-item",
    on: {
      "click": _vm.chooseAddr
    }
  }, [_c('h4', [_vm._v("收货地址")]), _vm.addrInfo ? _c('div', {
    staticClass: "van-cell have-addr van-cell--borderless"
  }, [_c('div', {
    staticClass: "van-cell__value van-cell__value--alone van-address-item__value"
  }, [_c('div', {
    staticClass: "van-radio",
    attrs: {
      "role": "radio",
      "tabindex": "0",
      "aria-checked": "true"
    }
  }, [_c('span', [_c('div', {
    staticClass: "van-address-item__name"
  }, [_c('span', {
    staticStyle: {
      "font-weight": "700",
      "margin-right": "8px"
    }
  }, [_vm._v(_vm._s(_vm.addrInfo.name))]), _vm._v(" " + _vm._s(_vm.addrInfo.tel) + " ")]), _vm.addrInfo.isDefault === 1 || _vm.addrInfo.is_default === 1 ? _c('span', {
    staticClass: "van-tag van-tag--round van-tag--danger van-address-item__tag"
  }, [_vm._v("默认")]) : _vm._e(), _c('span', {
    staticClass: "van-address-item__address"
  }, [_vm._v(_vm._s(_vm.addrInfo.address))])])])]), _c('i', {
    staticClass: "van-icon van-icon-arrow van-address-item__edit"
  })]) : _c('div', {
    staticClass: "van-cell van-cell--clickable van-cell--center van-cell--borderless van-contact-card van-contact-card--add",
    attrs: {
      "role": "button",
      "tabindex": "0"
    }
  }, [_c('i', {
    staticClass: "van-icon van-icon-add-square van-cell__left-icon"
  }), _c('div', {
    staticClass: "van-cell__value van-cell__value--alone van-contact-card__value"
  }, [_vm._v(" 添加收货地址 ")]), _c('i', {
    staticClass: "van-icon van-icon-arrow van-cell__right-icon"
  })])]) : _vm._e(), !_vm.$route.query.skill && _vm.goodsData.recommend_goods && _vm.goodsData.recommend_goods.length !== 0 ? _c('div', {
    staticClass: "update-container"
  }, [_c('div', {
    staticClass: "content"
  }, [_vm._m(0), _c('div', {
    staticClass: "update-list"
  }, [_c('van-checkbox-group', {
    attrs: {
      "max": 1
    },
    on: {
      "change": _vm.changeUpdateCheckbox
    },
    model: {
      value: _vm.checkedUpdateId,
      callback: function ($$v) {
        _vm.checkedUpdateId = $$v;
      },
      expression: "checkedUpdateId"
    }
  }, _vm._l(_vm.goodsData.recommend_goods, function (item) {
    return _c('van-checkbox', {
      key: item.id,
      attrs: {
        "name": item.id,
        "checked-color": "#ee0a24"
      },
      on: {
        "click": function ($event) {
          return _vm.clickUpdateCheckbox(item);
        }
      }
    }, [_c('van-card', {
      attrs: {
        "price": item.price,
        "thumb": item.thumb_pic,
        "title": item.title,
        "desc": item.check_items,
        "lazy-load": ""
      },
      scopedSlots: _vm._u([{
        key: "bottom",
        fn: function () {
          return [_c('van-tag', {
            attrs: {
              "plain": "",
              "type": "primary",
              "size": "medium"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.showDetail(item.title, item.id);
              }
            }
          }, [_vm._v(" 查看 ")])];
        },
        proxy: true
      }], null, true)
    })], 1);
  }), 1)], 1)])]) : _vm._e(), _c('van-checkbox-group', {
    staticClass: "discount",
    attrs: {
      "max": 1
    },
    model: {
      value: _vm.useDiscount,
      callback: function ($$v) {
        _vm.useDiscount = $$v;
      },
      expression: "useDiscount"
    }
  }, [_c('van-cell-group', [_c('van-cell', {
    staticClass: "kcoin",
    attrs: {
      "value": '余额 ' + _vm.useKcoin.balance
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('van-checkbox', {
          attrs: {
            "name": "kcoin",
            "checked-color": "#ee0a24",
            "disabled": _vm.isDisabledUseKcoin
          },
          on: {
            "click": function ($event) {
              return _vm.clickDiscountCheckbox('kcoin');
            }
          }
        }), _c('span', [_vm._v("K币抵扣 (最多可使用" + _vm._s(_vm.useKcoin.limit) + "元)")])];
      },
      proxy: true
    }], null, false, 1694457611)
  }), _c('van-cell', {
    staticClass: "coupons",
    attrs: {
      "is-link": ""
    },
    on: {
      "click": _vm.handleClickCoupons
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('van-checkbox', {
          attrs: {
            "name": "coupons",
            "checked-color": "#ee0a24"
          },
          nativeOn: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.clickDiscountCheckbox('coupons');
            }
          }
        }), _c('span', [_vm._v("优惠券/码")]), _c('van-tag', {
          style: {
            background: 'linear-gradient(90deg, #FC6E3C 0%, #E01B23 100%)',
            marginLeft: '8px'
          }
        }, [_vm._v(_vm._s((_vm.couponsData.can_use || []).length) + "张可用")])];
      },
      proxy: true
    }], null, false, 349479069)
  }, [[_c('span', {
    staticStyle: {
      "color": "#E32225"
    }
  }, [_vm._v("-¥" + _vm._s(!_vm.discountData.discount ? "0.00" : _vm.discountData.diff_amount))])]], 2)], 1)], 1), _c('OrderInfo', {
    attrs: {
      "discount": _vm.discountData.diff_amount,
      "payPrice": _vm.discountData.total_price,
      "price": _vm.discountData.origin_price_total,
      "discountType": _vm.useDiscount[0]
    }
  }), _c('GoodsDetailPopup', {
    attrs: {
      "goodsDetailList": _vm.goodsDetailArr
    },
    on: {
      "onSubmit": _vm.onSubmit
    },
    model: {
      value: _vm.showGoodsPopup,
      callback: function ($$v) {
        _vm.showGoodsPopup = $$v;
      },
      expression: "showGoodsPopup"
    }
  }, [_vm._v(" " + _vm._s(_vm.showGoodsPopupTitle) + " ")]), _c('CouponsPopup', {
    ref: "couponsPopup",
    attrs: {
      "show": _vm.showCouponsPopup,
      "code": _vm.couponsCode,
      "useLoading": _vm.useLoading,
      "couponsData": _vm.couponsData
    },
    on: {
      "update:show": function ($event) {
        _vm.showCouponsPopup = $event;
      },
      "update:code": function ($event) {
        _vm.couponsCode = $event;
      },
      "use": _vm.onUse,
      "checked": _vm.handleCheckedCoupon
    }
  }), _c('van-submit-bar', {
    attrs: {
      "button-text": "提交订单",
      "loading": _vm.submitLoading
    },
    on: {
      "submit": _vm.onSubmit
    }
  }, [_c('div', {
    staticClass: "van-submit-bar__text",
    staticStyle: {
      "text-align": "left"
    }
  }, [_c('div', [_c('span', [_vm._v("合计：")]), _c('span', {
    staticClass: "van-submit-bar__price"
  }, [_vm._v(" ¥ "), _c('span', {
    staticClass: "van-submit-bar__price van-submit-bar__price--integer"
  }, [_vm._v(" " + _vm._s(_vm.discountData.total_price) + " ")])])]), _c('span', {
    staticStyle: {
      "color": "#666"
    }
  }, [_vm._v(" 已优惠："), _c('em', {
    staticStyle: {
      "color": "#D13939"
    }
  }, [_vm._v("¥" + _vm._s(_vm.discountData.diff_amount))])])])])], 1) : _c('van-empty', {
    attrs: {
      "image": "https://khome2.tuzuu.com/klfront/newlogo.png",
      "description": "正在加载..."
    }
  })], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "update-content"
  }, [_c('div', {
    staticClass: "title"
  }, [_vm._v("优惠升级推荐")]), _c('div', {
    staticClass: "label"
  }, [_vm._v("多种体检套餐选择，体检项目更加丰富")])]);
}];
export { render, staticRenderFns };