<template>
    <van-popup v-model="showPopup" position="bottom" round closeable :style="{ height: '65%' }">
        <div class="top">
            <h1>优惠券/码</h1>
            <div class="tip">
                <van-icon color="#E52A27" name="warning-o" size="16" />
                <span>优惠码与优惠券不可叠加使用</span>
            </div>
            <div class="input between">
                <van-field v-model="couponsCode" placeholder="请输入优惠码" closeable />
                <van-button @click="handleUse" :disabled="!couponsCode" :loading="useLoading" color="linear-gradient(90deg, #FEAC43 0%, #FE9420 100%)">使 用</van-button>
            </div>
            <van-tabs v-model="active" title-active-color="#17AD84" title-inactive-color="#232323">
                <van-tab :title="`可用优惠券 (${(couponsData.can_use || []).length || 0})`"></van-tab>
                <van-tab :title="`不可用优惠券 (${(couponsData.not_use || []).length || 0})`"></van-tab>
            </van-tabs>
        </div>

        <div class="coupons">
            <template v-if="active === 0">
                <template v-if="couponsData.can_use && couponsData.can_use.length > 0">
                    <van-checkbox-group v-model="useCoupons" :max="1">
                        <Coupons v-for="it in couponsData.can_use || []" :key="it.id" :data="it">
                            <template #right>
                                <van-checkbox
                                    class="coupon-slot"
                                    :name="it.id"
                                    @click="clickCouponCheckbox(it)"
                                />
                            </template>
                        </Coupons>
                    </van-checkbox-group>
                </template>
                <van-empty v-else description="暂无优惠券" />
            </template>
            <template v-else>
                <template v-if="couponsData.not_use && couponsData.not_use.length > 0">
                    <Coupons v-for="it in couponsData.not_use || []" :key="it.id" :data="it" opacity istag :reason="it.reason" />
                </template>
                <van-empty v-else description="暂无优惠券" />
            </template>
        </div>
    </van-popup>
</template>

<script>
import Coupons from "@/components/Coupon/Coupon";

export default {
    name: "CouponsPopup",
    data() {
        return {
            active: 0,
            useCoupons: []
        }
    },
    props: {
        show: Boolean,
        useLoading: Boolean,
        code: String,
        couponsData: Object,
    },
    components: {
        Coupons
    },
    computed: {
        showPopup: {
            get() {
                return this.show;
            },
            set(val) {
                this.$emit("update:show", val);
            }
        },
        couponsCode: {
            get() {
                return this.code;
            },
            set(val) {
                this.$emit("update:code", val);
            }
        }
    },
    methods: {
        handleUse() {
            if (this.useCoupons[0]) {
                this.useCoupons.splice(0, 1);
            }
            this.$emit('use');
        },
        clickCouponCheckbox(data) {
            if (data.id === this.useCoupons[0]) {
                // 选中
                this.$emit('checked', this.useCoupons[0]);
                this.$emit("update:show", false);
                this.couponsCode = "";
            } else {
                if (this.useCoupons[0]) {
                    // 切换
                    this.useCoupons.splice(0, 1, data.id);
                    this.$emit('checked', this.useCoupons[0]);
                    this.$emit("update:show", false);
                    this.couponsCode = "";
                } else {
                    // 取消
                    this.$emit('checked', this.useCoupons[0]);
                    this.couponsCode = "";
                }
            }
        },
        resetUseCoupons() {
            this.useCoupons.splice(0, 1);
        }
    },
    watch: {
        couponsData() {
            if (this.useCoupons[0]) {
                this.useCoupons.splice(0, 1);
            }
        },
    }
}
</script>

<style lang="less" scoped>
.van-empty {
    padding: 0;
}
.top {
    padding: 0 20px;
    text-align: center;
    color: #232323;
    h1 {
        font-size: 16px;
        padding: 14px 0;
    }
    .tip {
        font-size: 14px;
        span {
            vertical-align: 3px;
            margin-left: 4px;
        }
    }
    .input {
        height: 40px;
        margin: 12px 0 6px;
        .van-cell::after {
            border: none;
        }
        .van-field {
            border: 1px solid #bebebe;
            border-radius: 4px;
            height: 100%;
            padding: 8px 10px;
            /deep/ .van-field__control::placeholder {
                color: #999;
            }
        }
        .van-button {
            white-space: nowrap;
            width: 90px;
            margin-left: 15px;
            flex-shrink: 0;
            height: 100%;
        }
    }
    /deep/ .van-tabs__line {
        display: none;
    }
    /deep/ .van-tab--active {
        font-weight: bold;
    }
}
.coupons {
    padding: 15px;
    box-sizing: border-box;
    height: calc(100% - 172px);
    overflow-y: auto;
    background: #F6F6F6;
    .coupon {
        &:not(:last-of-type) {
            margin-bottom: 12px;
        }
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    /deep/ .van-checkbox__icon--checked .van-icon {
        background: linear-gradient(180deg, #00C696 0%, #00B893 100%) !important;
        border-color: #00B893 !important;
    }
}
</style>