<template>
    <div class="order-info">
        <van-cell class="title">订单信息</van-cell>
        <van-cell class="price" title="商品金额" :value="`¥${price}`"></van-cell>
        <van-cell class="discount" :class="{
            red: discount != 0
        }" :title="discountTitle" :value="`- ¥${discount}`"></van-cell>
        <van-cell class="payPrice" title="">
            合计：<em>¥{{ payPrice }}</em>
        </van-cell>
    </div>
</template>

<script>
export default {
    name: "OrderInfo",
    props: ["discount", "payPrice", "price", "discountType"],
    computed: {
        discountTitle() {
            if (this.discountType === "kcoin") {
                return "K币抵扣";
            }
            if (this.discountType === "coupons") {
                return "优惠券/码";
            }
            return "暂无";
        }
    }
}
</script>

<style lang="less" scoped>
.order-info {
    margin-top: 10px;
    border-radius: 10px;
    overflow: hidden;
    font-size: 14px;
    .van-cell {
        padding-left: 20px;
        padding-right: 25px;
        /deep/ .van-cell__value {
            color: #232323;
        }
        /deep/ .van-cell__title {
            color: #232323;
        }
        &::after {
            left: 0;
            right: 0;
        }
        &.price {
            padding-top: 15px;
            &::after {
                display: none;
            }
            /deep/ .van-cell__value {
                font-weight: bold;
            }
        }
        &.discount {
            padding-bottom: 15px;
            /deep/ .van-cell__value {
                font-weight: bold;
                
            }
            &.red {
                /deep/ .van-cell__value {
                    color: #D13939;
                }
            }
        }
        &.title {
            padding-top: 15px; 
            padding-bottom: 15px; 
            font-weight: bold;
        }
        &.payPrice {
            padding-top: 15px; 
            padding-bottom: 15px; 
            em {
                color: #D13939;
                font-weight: bold;
            }
        }
    }
    
}
</style>