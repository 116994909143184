
import { getGoodsDetail } from "@/utils/util";
import { weChatPay } from "@/wechat/weChatPay";
import GoodsDetailPopup from "@/components/GoodsDetailPopup";
// import CouponsStatus from "./components/CouponsStatus";
import CouponsPopup from "./components/CouponsPopup";
import OrderInfo from "./components/OrderInfo";

export default {
    name: "PlaceOrder",
    data: () => ({
        goodsData: {},
        buyCount: 1,
        checkedUpdateId: [],
        goodsDetailArr: [],
        showPage: false,
        prevBuyCount: -1, // 上一次购买数量
        prevUseCoupon: undefined,

        showGoodsPopupTitle: "",
        showGoodsPopup: false,

        addrInfo: null, // 收货地址信息
        prevUseDiscount: "",
        prevUseCouponsCode: "",
        
        checkedGoods: {},       // 当前选中的商品

        submitLoading: false,
        useLoading: false,
        couponsCode: "",    // 优惠码
        saveCouponsCode: undefined,   
        useKcoin: {},
        useDiscount: [],    // 使用的优惠
        couponsCodeOrCoupons: "", // 优惠码或优惠券
        couponId: undefined,    // 使用的优惠券id
        showCouponsPopup: false,
        couponsData: {},
        discountData: {
            discount: "0.00",
            total_price: "0.00",
            diff_amount: "0.00",
            origin_price_total: "0.00"
        },

    }),
    components: {
        GoodsDetailPopup,
        // CouponsStatus,
        OrderInfo,
        CouponsPopup,
    },
    async created() {
        const res = await axios.get("/goods_relation", {
            params: {
                goods_id: this.$route.params.id,
                role: this.$route.query.preson
            },
        });
        this.showPage = true;
        console.log(res);
        if (res.code == 1) {
            this.goodsData = res.data;
            this.checkedGoods = res.data;
            this.getKcoinData(res.data);
            if (res.data.address && Object.keys(res.data.address).length > 0) {
                const addr = res.data.address;
                this.addrInfo = {
                    name: addr.name,
                    tel: addr.tel,
                    address: addr.province + addr.city + addr.area + addr.detail,
                    id: addr.id,
                    is_default: 1
                };
            }
        }

        this.getDiscountData();
        this.getCouponsData();

        let addrInfo = sessionStorage.getItem("chooseAddr")
        if (addrInfo) {
            try {
                addrInfo = JSON.parse(addrInfo);
                if (typeof addrInfo !== "string") {
                    this.addrInfo = addrInfo;
                }
            } catch (error) {
                
            }
        }
    },
    computed: {
        priceByCount() {
            return this.checkedGoods.price * this.buyCount;
        },  
        isDisabledUseKcoin() {
            if (this.priceByCount < 1) {
                if (this.useDiscount[0] === "kcoin") {
                    this.useDiscount.splice(0, 1);
                }
                return true;
            }
            if (
                this.checkedGoods.can_use_coin == 0 ||
                this.checkedGoods.coin_limit == 0 ||
                this.checkedGoods.use_coin == 0
            ) {
                return true;
            }
            return false;
        },
        getUpdateGoodsById() {
            return this.goodsData.recommend_goods.find(item => item.id === this.checkedUpdateId[0]);
        },
        buyLimit() {
            let limit = this.checkedGoods.buy_limit;
            if (limit <= 0) {
                limit = Number.MAX_VALUE;
            }
            return limit;
        },
    },
    methods: {
        async onUse() {
            this.couponsCodeOrCoupons = "couponsCode";
            this.useLoading = true;
            try {
                const res = await this.getDiscountData();
                this.saveCouponsCode = this.couponsCode;
                this.useDiscount.splice(0, 1, "coupons");
                this.showCouponsPopup = false;
            } catch (err) {
                this.saveCouponsCode = undefined;
                this.$toast(err.message);
            }
            this.couponsCode = "";
            this.useLoading = false;
        },
        async getDiscountData() {
            const data = this.initDiscountData();
            const res = await axios.post("/check_coupon_code", data);
            console.log(res);
            if (res.code == 1) {
                this.discountData = res.data || {};
                return res.data;
            }
            if (this.useDiscount[0]) {
                this.useDiscount.splice(0, 1);
            }
            this.$refs.couponsPopup.resetUseCoupons();
            this.getDiscountData();
            throw res;
        },
        initDiscountData() {
            const data = {
                goods_id: this.checkedGoods.id,
                goods_number: this.buyCount,
            };
            const { groupid, preson } = this.$route.query;
            if (groupid !== void 0) {
                data.pin_id = groupid;
            }
            if (preson) {
                data.role = preson;
            }
            if (this.useDiscount[0] === 'coupons' && this.couponsCodeOrCoupons == "coupons" && this.couponId) {
                data.coupon_id = this.couponId;
            } else if (this.couponsCodeOrCoupons == "couponsCode") {
                data.code = this.couponsCode;
            } else if (this.useDiscount[0] === 'kcoin') {
                data.coin = 1;
            }   
            console.log(data);
            return data;
        },
        getKcoinData(data) {
            if (data.can_use_coin !== void 0) {
                this.useKcoin.balance = data.can_use_coin;
            }  
            this.useKcoin.limit = data.coin_limit || 0;
        },
        changeBuyCount() {
            this.useDiscount.splice(0, 1);
            this.couponsCodeOrCoupons = "";
            this.$refs.couponsPopup.resetUseCoupons();
            this.getDiscountData();
        },
        handleCheckedCoupon(id) {
            this.couponsCodeOrCoupons = "coupons";
            this.couponId = id;
            if (id) {
                this.useDiscount.splice(0, 1, "coupons");
            } else {
                this.useDiscount.splice(0, 1);
            }
            this.getDiscountData().catch(err => {
                this.$toast(err.message);
            });
        },
        handleClickCoupons() {
            this.showCouponsPopup = true;
        },
        async getCouponsData() {
            const res = await axios.get("/get_goods_coupon", {
                params: {
                    goods_id: this.checkedGoods.id,
                }
            })
            console.log(res);
            this.couponsData = res.data;
        },
        chooseAddr() {
            sessionStorage.setItem("chooseAddr", this.$route.fullPath);
            this.$router.push("/addrlist");
        },
        async onSubmit() {
            window._czc.push(["_trackEvent", "下单页", "提交订单id", , this.checkedGoods.id]);
            if (
                this.checkedGoods.id === this.cancelPayGoodsId &&
                this.buyCount === this.prevBuyCount &&
                this.useDiscount[0] === this.prevUseDiscount &&
                this.saveCouponsCode === this.prevUseCouponsCode && 
                this.couponId === this.prevUseCoupon
            ) {
                console.log("直接调用wx支付");
                this.wxPay();
            } else {
                // 创建订单
                console.log("创建订单");
                const data = this.initSubmitData();
                if (!data) {
                    return;
                }
                this.prevBuyCount = this.buyCount; // 保存上一次购买份数
                this.prevUseDiscount = this.useDiscount[0]; // 保存上一次购买使用的优惠
                this.prevUseCouponsCode = this.saveCouponsCode; // 保存上一次使用的优惠码
                this.prevUseCoupon = this.couponId; // 保存上一次使用的优惠券id
                
                this.submitLoading = true;
                console.log(data);
                const res = await axios.post("/create_order", data);
                this.submitLoading = false;
                console.log(res);
                if (res.code == 1) {
                    this.order_sn = res.data.orderSn;
                    if (res.data.payConfig) {
                        this.wxPayConfig = res.data.payConfig;
                        this.wxPay();
                    } else {
                        this.wxPaySuccess();
                    }   
                } else if (res.code != 5202) {
                    this.$toast(res.message);
                }
            }
        },
        /**
         * 初始化提交数据
         */
        initSubmitData() {
            const data = {
                goods_id: this.checkedGoods.id,
                goods_number: this.buyCount,
            }

            const father_id = localStorage.getItem("father_id");
            const channel_id = localStorage.getItem("father_channel_id");
            const { mid, task_id, task_user_id, groupid, preson, is_express } = this.$route.query;
            
            if (task_id > 0) {
                data.mid = mid;
                data.task_id = task_id;
                data.task_user_id = task_user_id;
            } else {
                if (+father_id && +channel_id) {
                    data.father_id = father_id;
                    data.channel_id = channel_id;
                }
            }
            if (groupid !== void 0) {
                data.pin_id = groupid;
            }
            if (is_express == 1) {
                if (this.addrInfo) {
                    data.address_id = this.addrInfo.id;
                } else {
                    this.$toast("请先选择收货地址");
                    return;
                }
            }
            if (preson) {
                data.role = preson;
            }
            if (this.useDiscount[0] === 'coupons' && this.couponsCodeOrCoupons == "coupons" && this.couponId) {
                data.coupon_id = this.couponId;
            } else if (this.useDiscount[0] === 'coupons' && this.couponsCodeOrCoupons == "couponsCode" && this.saveCouponsCode) {
                data.code = this.saveCouponsCode;
            } else if (this.useDiscount[0] === 'kcoin') {
                data.coin = 1;
            }  
            return data;
        },
        wxPay() {
            weChatPay({
                wxPayConfig: this.wxPayConfig,
                onSuccess: () => {
                    this.wxPaySuccess();
                },
                onCancel: () => {
                    this.wxPayCancel();
                },
            });
        },
        wxPaySuccess() {
            const toast = this.$toast.loading({
                duration: 0,
                forbidClick: true,
                message: "请稍候...",
            });
            this.prevBuyCount = -1;
            this.prevUseDiscount = "";
            setTimeout(() => {
                toast.type = "success";
                toast.message = "支付完成";
                setTimeout(() => {
                    toast.clear();
                    this.$router.push({
                        path: "/pay_success",
                        query: {
                            order_sn: this.order_sn,
                        },
                    });
                }, 1500);
            }, 700);
        },
        wxPayCancel() {
            this.cancelPayGoodsId = this.checkedGoods.id;
            console.log("cancel wx支付");
        },
        async showDetail(title, id) {
            console.log(id);
            const toast = this.$toast.loading({
                message: "加载中...",
                forbidClick: true,
                duration: 0,
            });
            this.checkedUpdateId.splice(0, 1, id);
            this.showGoodsPopupTitle = title;
            const res = await axios.get("/goods_detail", {
                params: {
                    goods_id: id,
                },
            });
            toast.clear();
            if (res.code != 1) {
                this.$toast(res.message);
                return;
            }
            this.goodsDetailArr = getGoodsDetail(res.data.goods[0]);
            this.showGoodsPopup = true;
        },
        changeUpdateCheckbox() {
            this.couponId = undefined;
            this.couponsCodeOrCoupons = "";
            this.useDiscount.splice(0, 1);
            this.$refs.couponsPopup.resetUseCoupons();
            if (this.checkedUpdateId[0]) {
                this.checkedGoods = this.getUpdateGoodsById;
            } else {
                this.checkedGoods = this.goodsData;
            }
            this.getKcoinData(this.checkedGoods);
            this.getCouponsData();
            this.getDiscountData().catch(err => {
                this.$toast(err.message);
            });
        },
        clickUpdateCheckbox(data) {
            window._czc.push(["_trackEvent", "下单页", "点击关联id", , data.id]);
            if (data.id === this.checkedUpdateId[0]) {
                return;
            } else {
                if (this.checkedUpdateId[0]) {
                    this.checkedUpdateId.splice(0, 1, data.id);
                }
            }
        },
        clickDiscountCheckbox(type) {
            if (this.isDisabledUseKcoin && type === "kcoin") {
                return;
            }

            this.couponsCodeOrCoupons = "";
            this.couponId = undefined;
            this.$refs.couponsPopup.resetUseCoupons();
            if (type === this.useDiscount[0]) {
                // 选中
            } else {
                if (this.useDiscount[0]) {
                    // 切换
                    this.useDiscount.splice(0, 1, type);
                } else {
                    // 取消
                }
            }
            this.getDiscountData().catch(err => {
                this.$toast(err.message);
            });
        },
    },
    beforeRouteLeave(to, form, next) {
        let addr = sessionStorage.getItem("chooseAddr")
        if (addr) {
            try {
                addr = JSON.parse(addr);
                if (typeof addr === "object") {
                    sessionStorage.removeItem("chooseAddr");
                }
            } catch (error) {
                next(); 
            }
        }
        next();        
    }
};
